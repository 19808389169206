<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="user_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteMenuStudent()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-2 col-lg-3 col-sm-4">
                  <app-input
                    name="kyy_menu_name"
                    type="string128"
                    rules="required"
                    v-model="entry.name"
                  />
                  <!-- error msg -->
                  <!-- <app-error-message
                    v-if="!passes('name')"
                    :message="t('common_vn.required', { name: t('menu_student.name') })"
                  ></app-error-message> -->
                </div>

                <div class="col-xl-1 col-lg-2 col-sm-2 col-form-label text-right text-bold">
                  {{ $t("menu_student.name_vn") }} <span class="app-require">＊</span>
                </div>
                <div class="col-xl-2 col-lg-3 col-sm-4">
                  <app-input
                    name="kyy_menu_name_vn"
                    type="string128"
                    rules="required"
                    v-model="entry.name_vn"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.sell_date") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-date-range-picker
                    name="kyy_menu_sell_date"
                    v-model="entry.sell_date"
                    timePicker24Hour="false"
                    rules="required"
                    format="YYYY-MM-DD"
                    @input="entry.sell_date = $event"
                    :minDate="minDate"
                  />
                </div>
              </div>
              <!-- SIZE ~ PRICE -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.price_s") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_menu_price_s"
                    type="number-length:9"
                    rules="required|min_value:1"
                    v-model="entry.price_s"
                  />
                </div>
              </div>
              <!-- END SIZE ~ PRICE -->

              <!-- SIZE ~ PRICE -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.price_m") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_menu_price_m"
                    type="number-length:9"
                    rules="required|min_value:1"
                    v-model="entry.price_m"
                  />
                </div>
              </div>
              <!-- END SIZE ~ PRICE -->

              <!-- SIZE ~ PRICE -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.price_l") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_menu_price_l"
                    type="number-length:9"
                    rules="required|min_value:1"
                    v-model="entry.price_l"
                  />
                </div>
              </div>
              <!-- END SIZE ~ PRICE -->

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.description") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="kyy_menu_description"
                    type="string128"
                    rules="required"
                    v-model="entry.description"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.description_vn") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="kyy_menu_description_vn"
                    type="string128"
                    rules="required"
                    v-model="entry.description_vn"
                  />
                </div>
              </div>
              
              <validation-provider
                name="kyy_menu_image"
                rules="required"
                v-slot="{ errors, invalid, validated }"
              >
                <div class="form-group row ">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("menu_student.image") }}<span class="app-require">＊</span></label>
                  <div class="col-xl-5 col-lg-8 col-sm-8">
                    <app-single-file-input accept="image/jpeg, image/png, image/jpg" 
                      name="kyy_menu_image"
                      :imageSrc="entry.image_path"
                      @input="handlerUploadImage"
                      @updated_at="entry.data_updated_at = $event"
                      :id-editing="idEditing" 
                      rules="required"
                      v-model="entry.image"
                      :allowedExtension="allowedExtension" >
                    </app-single-file-input>
                    <label class="error">{{ errors[0] }}</label>
                  </div>
                </div>
              </validation-provider>
              

              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common_vn.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDelete">
                  {{ $t('common_vn.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" @click="createOrUpdate" type="button">
                  {{  $t('common_vn.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createOrUpdate" >
                  {{  $t('common_vn.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { createHmac } from 'crypto';
import AppTextarea from "../../../components/forms/AppTextarea";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROUTES} from "@constants";
import path from 'path';
export default {
  name: "BookMasterCreateOrEdit",

  components: {
    AppTextarea,
    AppDateRangePicker,
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        id: null,
        name: '',
        name_vn: '',
        description: '',
        description_vn: '',
        role: 1,
        sell_date: '',
        price_s: 80000, 
        price_m: 90000,
        price_l: 100000,
        image_path: null,
        image: null,
        data_updated_at: null,
      },
      apiKeyId: null,
      meta: {
        apartments:[],
        opening_time:[],
        closing_time:[],
      },
      minDate : moment().format('YYYY-MM-DD'),
      idEditing: id,
      entryNameUpdate: "",
      startValidate : !!id,
      allowedExtension : ['image/jpeg', 'image/png', 'image/jpg']
    };
  },
  watch: {
  },

  mounted: async function () {
    if (this.idEditing) {
      this.startValidate = false;
      let response = await this.$request.get(this.ENDPOINT.MENU_STUDENT_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
        await this.redirectDefaultValue()
      } else {
        this.entry = _.cloneDeep(response.data.data);
        this.entry.path = 'menu_student/'
        this.entry.image_path = this.entry.image.url;
        this.startValidate = true;
      }
    }
  },

  methods: {
    handlerUploadImage(data) {
      this.entry.image = data
      this.entry.image_path = data
      this.entry.image_updated_at = new Date().getTime()
    },
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BOOK_MASTER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() { 
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.MENU_STUDENT_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.MENU_STUDENT_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },

    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.MENU_STUDENT_LIST, query: {}
      });
    },
    handleDelete() {
      this.$refs.user_modal_delete.show();
    },
    async deleteMenuStudent() {
      const res = await this.$request.delete(this.ENDPOINT.MENU_STUDENT_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.user_modal_delete.hide();
      }
    },
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
